import React, { useEffect, useState } from "react";
import ServiceRequest from "../../utils/service-request";
import { serviceHost } from "../../common/const";
import SelectMemberComponent from "./select-member";
import MultipleMemberComponent from "./multiple-member";
import UploadTasksFile from "./upload-tasks-file";
import TagInput from "./tag-input";
import { saveAs } from "file-saver";
import XLSX from "xlsx";
import * as uploadservice from "../../services/upload/upload-service";

const TaskFieldsConfig = ({ projectId }) => {
  const initialData = [
    {
      label: "Task Name",
      key: "title",
      dataType: "string",
      isSystem: true,
      isRequired: true,
      defaultValue: "",
      uiOrder: 0,
      excelOrder: 0,
    },
    {
      label: "Status",
      key: "status",
      dataType: "string",
      componentType: "dropdown",
      options: [
        { label: "New", value: "new" },
        { label: "In Progress", value: "inprogress" },
        { label: "On hold", value: "onhold" },
        { label: "Completed", value: "completed" },
      ],
      isSystem: true,
      isRequired: true,
      defaultValue: "new",
      uiOrder: 0,
      excelOrder: 0,
    },
    {
      label: "Task Dependency",
      key: "depId",
      dataType: "string",
      isSystem: true,
      isRequired: false,
      defaultValue: "",
      uiOrder: 0,
      excelOrder: 0,
    },
    {
      label: "Story Point (1 SP = 1 hr)",
      key: "storyPoint",
      dataType: "number",
      isSystem: true,
      isRequired: true,
      defaultValue: 1,
      uiOrder: 0,
      excelOrder: 0,
    },
    {
      label: "Start Date",
      key: "startDate",
      dataType: "date",
      isSystem: true,
      isRequired: false,
      defaultValue: "",
      uiOrder: 0,
      excelOrder: 0,
    },
    {
      label: "End Date",
      key: "endDate",
      dataType: "date",
      isSystem: true,
      isRequired: false,
      defaultValue: "",
      uiOrder: 0,
      excelOrder: 0,
    },
    {
      label: "Task Type",
      key: "taskType",
      dataType: "string",
      componentType: "dropdown",
      options: [
        { label: "Task", value: "task" },
        { label: "Issue", value: "issue" },
      ],
      isSystem: true,
      isRequired: true,
      defaultValue: "task",
      uiOrder: 0,
      excelOrder: 0,
    },
    {
      label: "Priority",
      key: "priority",
      dataType: "string",
      componentType: "dropdown",
      options: [
        { label: "High", value: "high" },
        { label: "Low", value: "low" },
        { label: "Medium", value: "medium" },
        { label: "Urgent", value: "urgent" },
      ],
      isSystem: true,
      isRequired: true,
      defaultValue: "medium",
      uiOrder: 0,
      excelOrder: 0,
    },
    {
      label: "Description",
      key: "description",
      dataType: "string",
      isSystem: true,
      isRequired: true,
      defaultValue: "",
      uiOrder: 0,
      excelOrder: 0,
    },
    {
      label: "Tag",
      key: "tag",
      dataType: "component",
      component: TagInput,
      isSystem: true,
      isRequired: false,
      defaultValue: "",
      uiOrder: 0,
      excelOrder: 0,
    },
    {
      label: "Upload Files",
      key: "uploadFiles",
      dataType: "component",
      component: UploadTasksFile,
      isSystem: true,
      isRequired: false,
      uiOrder: 0,
      excelOrder: 0,
    },
    {
      label: "Replicate for Multiple Members",
      key: "allowMultipleUsers",
      dataType: "component",
      component: MultipleMemberComponent,
      isSystem: true,
      isRequired: false,
      uiOrder: 0,
      excelOrder: 0,
    },
    {
      label: "Select Members",
      key: "selectUsers",
      dataType: "component",
      component: SelectMemberComponent,
      isSystem: true,
      isRequired: false,
      uiOrder: 0,
      excelOrder: 0,
    },
  ];

  const [formData, setFormData] = useState(initialData);
  const [selectedFields, setSelectedFields] = useState(
    initialData.reduce((acc, field) => ({ ...acc, [field.key]: false }), {})
  );

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        // Fetch custom task fields
        const response = await ServiceRequest(
          "get",
          "json",
          `${serviceHost}/projects/getCustomTasksField/${projectId}?level=task`
        );

        const customTasksField = response.data.customTasksField || [];

        // Fetch existing configuration (if any)
        const existingConfigResponse = await ServiceRequest(
          "get",
          "json",
          `${serviceHost}/projects/getConfig/${projectId}`
        );
        const existingConfig = existingConfigResponse.data?.config || [];

        // Merge custom task fields with initial data and existing config
        const updatedFields = [...initialData, ...customTasksField].map(
          (field) => {
            const savedField = existingConfig.find(
              (configField) => configField.key === field.key
            );

            return savedField
              ? {
                  ...field,
                  label: savedField.label || field.label,
                  isRequired: savedField.isRequired ?? field.isRequired,
                  defaultValue: savedField.defaultValue || field.defaultValue,
                  uiOrder: savedField.uiOrder || field.uiOrder,
                  excelOrder: savedField.excelOrder || field.excelOrder,
                }
              : field;
          }
        );

        // Set formData and selectedFields based on existing config
        setFormData(updatedFields);
        const selectedState = updatedFields.reduce(
          (acc, field) => ({
            ...acc,
            [field.key]: !!existingConfig.find(
              (configField) => configField.key === field.key
            ),
          }),
          {}
        );
        setSelectedFields(selectedState);
      } catch (error) {
        console.error("Error fetching configuration:", error);
      }
    };

    fetchCustomFields();
  }, [projectId]);

  // useEffect(() => {
  //   ServiceRequest(
  //     "get",
  //     "json",
  //     `${serviceHost}/projects/getCustomTasksField/${projectId}?level=task`
  //   )
  //     .then((response) => {
  //       console.log("API Response:", response);

  //       const customTasksField = response.data.customTasksField;

  //       if (Array.isArray(customTasksField)) {
  //         const updatedFields = customTasksField.map((field) => ({
  //           ...field,
  //           uiOrder: field.uiOrder || 0,
  //           excelOrder: field.excelOrder || 0,
  //           isRequired: field.isMandatory || false,
  //         }));
  //         setFormData((prevData) => [...prevData, ...updatedFields]);
  //       } else {
  //         console.error(
  //           "Expected an array for customTasksField but got:",
  //           customTasksField
  //         );
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching custom task fields:", error);
  //     });
  // }, [projectId]);

  const handleInputChange = (index, e) => {
    const { name, value, type, checked } = e.target;
    const updatedFormData = [...formData];
    if (name === "isRequired") {
      if (!updatedFormData[index].isRequired) {
        updatedFormData[index][name] = checked;
      }
    } else if (type === "checkbox") {
      updatedFormData[index][name] = checked;
    } else {
      updatedFormData[index][name] = value;
    }
    setFormData(updatedFormData);
  };

  const handleCheckboxChange = (index) => {
    const updatedSelectedFields = {
      ...selectedFields,
      [formData[index].key]: !selectedFields[formData[index].key],
    };
    setSelectedFields(updatedSelectedFields);
  };

  const generateExcelFile = async () => {
    // Filter formData to include only selected fields
    const selectedFormData = formData.filter(
      (field) => selectedFields[field.key]
    );

    // Sort formData by excelOrder
    const sortedData = [...selectedFormData].sort(
      (a, b) => a.excelOrder - b.excelOrder
    );

    //const labels = [sortedData.map((field) => field.label)];

    // Combine field keys with isSystem values in the required format
    const combinedData = sortedData.map(
      (field) => `${field.key} (${field.isSystem ? "Yes" : "No"})`
    );

    // Add the combined data as a single row
    const sheetData = [combinedData];

    // Create a new workbook and worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(sheetData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "TaskFieldsConfig");

    try {
      // Write the workbook and generate a binary string
      const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

      // Convert binary string to a Blob
      const buffer = new ArrayBuffer(wbout.length);
      const view = new Uint8Array(buffer);
      for (let i = 0; i < wbout.length; i++) {
        view[i] = wbout.charCodeAt(i) & 0xff;
      }

      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Save the file using FileSaver
      saveAs(blob, "TaskFieldsConfig.xlsx");
      // Define the projectId for uploading
      // const projectId = "66e914e647cb7ff353f570b0";

      // Upload the file using the uploadTaskFieldsConfig function
      // const { response, err } = await uploadservice.uploadTaskFieldsConfig(
      //   blob,
      //   projectId
      // );
    } catch (error) {
      console.error("Error generating Excel file:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Filter and map the form data to the desired format
    const filteredData = formData
      .filter((field) => selectedFields[field.key])
      .map((field, index) => ({
        key: field.key,
        label: field.label,
        dataType: field.dataType || "string",
        defaultValue: field.defaultValue || "",
        isRequired: field.isRequired ?? false,
        isSystem: field.isSystem ?? false,
        uiOrder: field.uiOrder || index + 1,
        excelOrder: field.excelOrder || index + 1,
        ...(field.componentType === "dropdown" && {
          componentType: field.componentType,
          options: field.options || [],
        }),
      }));

    if (filteredData.length === 0) {
      console.warn("No fields selected for submission.");
      return;
    }

    const configData = {
      projectId,
      config: filteredData,
    };

    try {
      let existingConfigResponse;

      try {
        // Attempt to fetch existing configuration
        existingConfigResponse = await ServiceRequest(
          "get",
          "json",
          `${serviceHost}/projects/getConfig/${projectId}`
        );

        // If configuration exists, delete it first
        if (existingConfigResponse && existingConfigResponse.data) {
          await ServiceRequest(
            "delete",
            "json",
            `${serviceHost}/projects/deleteConfig/${projectId}`
          );
          console.log("Previous configuration deleted.");
        } else {
          // If no configuration data is found, log it
          console.log("No existing configuration found.");
        }
      } catch (fetchError) {
        // Handle fetch errors. If it's a 404 error, proceed with creation.
        if (fetchError.response && fetchError.response.status === 404) {
          console.log(
            "No existing configuration found, proceeding to create new configuration."
          );
        } else {
          throw fetchError;
        }
      }

      // Save the new configuration
      const response = await ServiceRequest(
        "post",
        "json",
        `${serviceHost}/projects/createConfig`,
        configData
      );

      if (response && response.data) {
        console.log("Task fields saved:", response.data);

        alert("Task fields configuration saved successfully!");

        // After successful save, generate Excel file
        generateExcelFile();
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (error) {
      console.error(
        "Error processing task fields:",
        error.response ? error.response.data : error.message
      );
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   // Filter and map the form data to the desired format
  //   const filteredData = formData
  //     .filter((field) => selectedFields[field.key])
  //     .map((field, index) => ({
  //       key: field.key,
  //       label: field.label,
  //       dataType: field.dataType || "string",
  //       defaultValue: field.defaultValue || "",
  //       isRequired: field.isRequired ?? false,
  //       isSystem: field.isSystem ?? false,
  //       uiOrder: field.uiOrder || index + 1,
  //       excelOrder: field.excelOrder || index + 1,
  //       ...(field.componentType === "dropdown" && {
  //         componentType: field.componentType,
  //         options: field.options || [],
  //       }),
  //     }));

  //   if (filteredData.length === 0) {
  //     console.warn("No fields selected for submission.");
  //     return;
  //   }

  //   const configData = {
  //     projectId,
  //     config: filteredData,
  //   };

  //   try {
  //     let existingConfigResponse;

  //     try {
  //       // Attempt to fetch existing configuration
  //       existingConfigResponse = await ServiceRequest(
  //         "get",
  //         "json",
  //         `${serviceHost}/projects/getConfig/${projectId}`
  //       );

  //       // If configuration exists, delete it first
  //       if (existingConfigResponse && existingConfigResponse.data) {
  //         await ServiceRequest(
  //           "delete",
  //           "json",
  //           `${serviceHost}/projects/deleteConfig/${projectId}`
  //         );
  //         console.log("Previous configuration deleted.");
  //       } else {
  //         // If no configuration data is found, log it
  //         console.log("No existing configuration found.");
  //       }
  //     } catch (fetchError) {
  //       // Handle fetch errors. If it's a 404 error, proceed with creation.
  //       if (fetchError.response && fetchError.response.status === 404) {
  //         console.log(
  //           "No existing configuration found, proceeding to create new configuration."
  //         );
  //       } else {
  //         throw fetchError;
  //       }
  //     }

  //     // Save the new configuration
  //     const response = await ServiceRequest(
  //       "post",
  //       "json",
  //       `${serviceHost}/projects/createConfig`,
  //       configData
  //     );

  //     if (response && response.data) {
  //       console.log("Task fields saved:", response.data);
  //     } else {
  //       console.error("Unexpected response:", response);
  //     }
  //   } catch (error) {
  //     console.error(
  //       "Error processing task fields:",
  //       error.response ? error.response.data : error.message
  //     );
  //   }
  // };

  return (
    <div className="container">
      <h2>Task Fields Configuration</h2>
      <form onSubmit={handleSubmit}>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Select</th>
              <th>Label</th>
              <th>Is System</th>
              <th>Is Required</th>
              <th>Default Value</th>
              <th>UI Order</th>
              <th>Excel Order</th>
            </tr>
          </thead>

          {formData.map((field, index) => (
            <tr key={index}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedFields[field.key] || false}
                  onChange={() => handleCheckboxChange(index)}
                />
              </td>
              <td>
                <input
                  type="text"
                  name="label"
                  value={field.label}
                  onChange={(e) => handleInputChange(index, e)}
                  className="form-control"
                />
              </td>
              <td>{field.isSystem ? "Yes" : "No"}</td>
              <td>
                <input
                  type="checkbox"
                  name="isRequired"
                  checked={field.isRequired}
                  onChange={(e) => handleInputChange(index, e)}
                  disabled={field.isRequired}
                />
              </td>
              <td>
                {field.componentType === "dropdown" ? (
                  <select
                    name="defaultValue"
                    value={field.defaultValue}
                    onChange={(e) => handleInputChange(index, e)}
                    className="form-control"
                  >
                    {field.options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input
                    type="text"
                    name="defaultValue"
                    value={field.defaultValue}
                    onChange={(e) => handleInputChange(index, e)}
                    className="form-control"
                  />
                )}
              </td>
              <td>
                <input
                  type="number"
                  name="uiOrder"
                  value={field.uiOrder}
                  onChange={(e) => handleInputChange(index, e)}
                  className="form-control"
                />
              </td>
              <td>
                <input
                  type="number"
                  name="excelOrder"
                  value={field.excelOrder}
                  onChange={(e) => handleInputChange(index, e)}
                  className="form-control"
                />
              </td>
              <td>
                {field.dataType === "dropdown" && field.options && (
                  <select
                    name="defaultValue"
                    value={field.defaultValue}
                    onChange={(e) => handleInputChange(index, e)}
                    className="form-control"
                  >
                    {field.options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                )}
              </td>
            </tr>
          ))}
        </table>

        <button type="submit" className="btn btn-primary">
          Save Configuration
        </button>
      </form>
    </div>
  );
};

export default TaskFieldsConfig;
