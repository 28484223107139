import React, { useState, useEffect } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  flexRender,
} from "@tanstack/react-table";
import "./tasks-table-view.css";
import ServiceRequest from "../../utils/service-request";
import { serviceHost } from "../../common/const";
import MultipleMemberComponent from "../project-config/multiple-member";

const TasksTableView = ({ tasks, proId }) => {
  const [headers, setHeaders] = useState([]);
  const [data, setData] = useState(tasks);
  const [users, setUsers] = useState({});
  const [showAssignUserModal, setShowAssignUserModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [projectUsers, setProjectUsers] = useState([]);
  const [isAssignUsersModalOpen, setIsAssignUsersModalOpen] = useState(false);

  const handleAssignUserClick = (task) => {
    console.log("Assign User Clicked for task:", task);
    setSelectedTask(task);
    setShowAssignUserModal(true);
  };

  const closeAssignUserModal = () => {
    setShowAssignUserModal(false);
  };

  const openAssignUsersModal = () => {
    setIsAssignUsersModalOpen(true);
  };

  const closeAssignUsersModal = () => {
    setIsAssignUsersModalOpen(false);
  };

  // Fetch saved config
  const fetchSavedConfig = async () => {
    try {
      const existingConfigResponse = await ServiceRequest(
        "get",
        "json",
        `${serviceHost}/projects/getConfig/${proId}`
      );

      let existingConfig = existingConfigResponse.data?.config || [];

      existingConfig = existingConfig.sort((a, b) => {
        return parseInt(a.excelOrder) - parseInt(b.excelOrder);
      });

      // Map existingConfig to headers for table
      const mappedHeaders = existingConfig.map((config) => ({
        title: config.label,
        accessorKey: config.key,
        isSystem: config.isSystem,
      }));

      // Update the headers state
      setHeaders(mappedHeaders.length > 0 ? mappedHeaders : defaultHeaders);
    } catch (error) {
      console.error("Error fetching saved config:", error);
      // Fallback to default headers if fetch fails
      setHeaders(defaultHeaders);
    }
  };

  const fetchUserData = async (userIds) => {
    try {
      const userPromises = userIds.map((userId) =>
        ServiceRequest("get", "json", `${serviceHost}/users/${userId}`)
      );
      const userResponses = await Promise.all(userPromises);
      console.log("userResponses", userResponses);
      const userMap = userResponses.reduce((acc, user) => {
        acc[user.data._id] = user.data.name;
        return acc;
      }, {});
      setUsers(userMap);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const fetchProjectUsers = async () => {
    try {
      const response = await ServiceRequest(
        "get",
        "json",
        `${serviceHost}/projects/${proId}`
      );
      console.log("Fetched project users:", response.data.users);
      setProjectUsers(response.data.users || []);
    } catch (error) {
      console.error("Error fetching project users:", error);
    }
  };

  useEffect(() => {
    fetchSavedConfig();
    const uniqueUserIds = [...new Set(tasks.map((task) => task.userId))];
    console.log("uniqueUserIds", uniqueUserIds);
    if (uniqueUserIds.length > 0) {
      fetchUserData(uniqueUserIds);
    }
    fetchProjectUsers();
  }, [proId, tasks]);

  // Define default headers if no config exists
  const defaultHeaders = [
    { title: "Task Title", accessorKey: "title" },
    { title: "Start Date", accessorKey: "startDate" },
    { title: "End Date", accessorKey: "endDate" },
  ];

  // Prepare data to include custom fields
  const prepareData = (tasks) => {
    return tasks.map((task) => {
      const customFields = task.customFieldValues || {};
      return {
        ...task,
        ...customFields,
        userName: users[task.userId] || "N/A",
      };
    });
  };

  useEffect(() => {
    const preparedData = prepareData(tasks);
    console.log("Prepared data:", preparedData);
    setData(preparedData);
  }, [tasks, users]);

  // Setup the table instance
  const tableInstance = useReactTable({
    data,
    columns: headers.map((header) => ({
      accessorKey: header.accessorKey,
      header: header.title,
      cell: (info) => {
        const value = info.getValue();
        const row = info.row.original;

        // if (header.accessorKey === "selectUsers") {
        //   console.log("selectUsers value:", row.userId);
        //   console.log("header.accessorKey:", header.accessorKey);
        //   return row.userId || "N/A";
        // }
        if (header.accessorKey === "selectUsers") {
          const userName = users[row.userId] || "N/A";
          console.log(
            "Rendering userName for userId:",
            row.userId,
            "=>",
            userName
          );
          return userName;
        }

        // Render value based on isSystem
        if (header.isSystem === false) {
          return row.customFieldValues?.[header.accessorKey] ?? "N/A";
        }

        // Check if value is an object, date, or other special type
        if (typeof value === "object" && value !== null) {
          if (value instanceof Date) {
            return value.toLocaleDateString();
          }
          return JSON.stringify(value); // Display as JSON string for objects
        }

        return value ?? "N/A"; // Default if value is null or undefined
      },
    })),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const handleAssignUsers = async (assignedUsers) => {
    try {
      console.log("Assigning users:", {
        taskId: selectedTask._id,
        assignedUsers: assignedUsers,
      });
      const response = await ServiceRequest(
        "post",
        "json",
        `${serviceHost}/tasks/assign`,
        {
          taskId: selectedTask._id,
          assignedUsers: assignedUsers,
        }
      );
      console.log("Users assigned successfully:", response.data);
      closeAssignUserModal();
    } catch (error) {
      console.error("Error assigning users:", error);
    }
  };

  return (
    <div>
      <button
        onClick={openAssignUsersModal}
        style={{ background: "#79F6FC", marginLeft: "5px", color: "black" }}
      >
        Assign task Users
      </button>
      <div className="table-container">
        {/* <button onClick={openAssignUsersModal} style={{ background: "green" }}>
        Assign task to Selected Users
      </button> */}
        <table className="tasks-table">
          <thead>
            {tableInstance.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="table-header">
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {tableInstance.getRowModel().rows.map((row, index) => (
            <tr
              key={row.id}
              className={index % 2 === 0 ? "table-row" : "table-row-alt"}
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}

              <td>
                <button
                  title="Assign Users"
                  onClick={() => handleAssignUserClick(row.original)}
                  style={{
                    background: "white",
                    border: "none",
                    color: "green",
                    padding: "5px 10px",
                    cursor: "pointer",
                  }}
                >
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </button>
              </td>
            </tr>
          ))}
        </table>
        <div className="pagination-controls">
          <button
            className="pagination-btn"
            onClick={() => tableInstance.previousPage()}
            disabled={!tableInstance.getCanPreviousPage()}
          >
            Previous
          </button>
          <span className="page-info">
            Page {tableInstance.getState().pagination.pageIndex + 1} of{" "}
            {tableInstance.getPageCount()}
          </span>
          <button
            className="pagination-btn"
            onClick={() => tableInstance.nextPage()}
            disabled={!tableInstance.getCanNextPage()}
          >
            Next
          </button>
        </div>

        {showAssignUserModal && (
          <div className="modal-overlay">
            <div className="assign-user-modal">
              {console.log("Rendering project users:", projectUsers)}
              <MultipleMemberComponent
                allowMultipleUsers={true}
                selectUsers={selectedTask?.selectUsers || []}
                onInputChange={(users) => handleAssignUsers(users)}
                onUserKeyPress={() => {}}
                dropdownHidden={false}
                projectUsersDropdown={
                  <div>
                    <ul>
                      {projectUsers.map((user) => (
                        <li
                          key={user._id}
                          onClick={() => handleAssignUsers([user._id])}
                        >
                          {user.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                }
                assignusers={<div>Assign users here</div>}
              />

              <div className="modal-actions">
                <button onClick={closeAssignUserModal}>Close</button>
              </div>
            </div>
          </div>
        )}

        {isAssignUsersModalOpen && (
          <div className="modal-overlay">
            <div className="assign-user-modal">
              <h3 style={{ color: "black" }}>Assign Task to Users</h3>

              {/* Render Task List Dropdown */}
              <div>
                <h4>Select Task</h4>
                <select
                  onChange={(e) => {
                    const task = tasks.find((t) => t._id === e.target.value);
                    setSelectedTask(task);
                  }}
                >
                  <option value="">Select Task</option>
                  {tasks.map((task) => (
                    <option key={task._id} value={task._id}>
                      {task.title}
                    </option>
                  ))}
                </select>
              </div>

              {/* Render User List */}
              <div>
                <MultipleMemberComponent
                  allowMultipleUsers={true}
                  selectUsers={selectedTask?.selectUsers || []}
                  onInputChange={(users) => handleAssignUsers(users)}
                  onUserKeyPress={() => {}}
                  dropdownHidden={false}
                  projectUsersDropdown={
                    <div>
                      <ul>
                        {projectUsers.map((user) => (
                          <li
                            key={user._id}
                            onClick={() => handleAssignUsers([user._id])}
                          >
                            {user.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  }
                  assignusers={<div>Assign users here</div>}
                />
              </div>

              <div className="modal-actions">
                <button onClick={closeAssignUsersModal}>Close</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TasksTableView;
